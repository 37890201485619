//================================================================
//  Tab: Unassigned 
//================================================================

//  Purpose: This is the standard for creating a tab, like the Unassigned tab

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors} from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';

//Functions

//Images


export default function Example() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const navigate = useNavigate();
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='flex flex-col px-[15px] gap-[20px]'>

            {/* Content Header */}
            <div className='flex flex-row-reverse'>
              Unassigned Header
            </div>

            {/* Content Body */}
            <div className='Page-Section-Container'>
              Unassigned Body
            </div>

          </div>
        }
      ></PageComponent>
    )
}